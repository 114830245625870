<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconAdd',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 512 512"
    role="presentation"
  >
    <g id="pinterest">
      <g>
        <g>
          <path
            :fill="color"
            d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
