<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconHamburgerMenu',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 512 512"
    aria-labelledby="HamburgerMenuIcon"
    role="presentation"
  >
    <title id="HamburgerMenuIcon">
      Mobile Menu - Select to Open
    </title>
    <g id="pinterest">
      <g>
        <g>
          <path
            :fill="color"
            d="M64 384h384v-42.666H64V384zm0-106.666h384v-42.667H64v42.667zM64 128v42.665h384V128H64z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
